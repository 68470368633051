import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors, Nav1Blue } from "../../../styles/helpers"

import SocialMedia from "../SocialMedia/SocialMedia"
import HeaderMenu from "./HeaderMenu"

const HeaderNav = () => {
  return (
    <HeaderNavStyled>
      <div className="wrapperNav">
        <div className="navTop">
          <div className="navTop__socialLinks">
            <SocialMedia />
          </div>
          <Link className="navTop__donate" to="/donations">
            Donate
          </Link>
          <Link className="navTop__volunteer" to="/membership">
            Become a Member
          </Link>
        </div>
        <div className="navBot">
          <HeaderMenu />
        </div>
      </div>
    </HeaderNavStyled>
  )
}

const HeaderNavStyled = styled.div`
  .wrapperNav {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding: 0;
  }

  .navTop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &__socialLinks {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: ${colors.colorSecondary};

      @media (min-width: 768px) {
        width: calc(50%);
      }
    }

    &__donate,
    &__volunteer {
      ${Nav1Blue};
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-top: 2rem;
      padding: 2rem 0;
      text-transform: uppercase;
      background-color: ${colors.colorTertiary};

      @media (min-width: 768px) {
        width: calc(25%);
        margin-top: 0;
      }


      &:hover {
        color: ${colors.white};
      }
    }

    &__donate {
      background-color: ${colors.colorPrimary};
      color: #fff;

      &:hover {
        color: ${colors.colorTertiary};
      }
    }
  }

  .navBot {
    width: 100%;
    padding: 0;
    background: ${colors.colorPrimary};

    @media (min-width: 768px) {
      padding: 1rem 0;
    }
  }
`

export default HeaderNav
